/* src/index.css */
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: blue;
}

.open-sans-300 {
    font-weight: 300;
}

.open-sans-400 {
    font-weight: 400;
}

.open-sans-600 {
    font-weight: 600;
}

.open-sans-800 {
    font-weight: 800;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'Univers';
  src: url('./font/UniversLTStd.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
