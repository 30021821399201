.modal-body {
  padding: 3em !important;
}

.modal-header {
  border-bottom: transparent !important;
}

.modal-footer {
  border-top: transparent !important;
}

.bg-body-tertiary {
  background-color: transparent !important;
}

.me-auto {
  margin: 0 auto !important;
}

footer {
  width: 100%;
  height: 200px;
  margin-top: 5em;
  padding: .5em;
  background-color: #f3f3f3;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App {
  text-align: left;
}

.contain {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.tipo-01 {
  color: #00758D;
  margin: 1em 0;
  font-family: 'Univers';
}

.tipo-02 {
  color: #EBA900;
  margin: 1em 0;
  font-family: 'Univers';
}

.logo {
  background-image: url("./img/logo_image.png"); /* The image used */
  width: 50%;
  background-position-x: left;
  background-position-y: top;
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: contain; /* Resize the background image to cover the entire container */
}

.link-style {
  text-decoration: none;
  color: #00758D;
}

.link-style:hover {
  text-decoration: underline;
  color: #000;
}

.talleres01 {
  background-color: #f3f3f3;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 10px;
}

.talleres02 {
  background-color: #d4f9f9;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 10px;
}

.talleres03 {
  background-color: #fce5bf;
  border-radius: 10px;
  padding: 5px 10px;
  margin: 10px;
}

.info {
  margin: 6em 4em;
  width: 50%;
}

.img-logo {
  width: 100%;
  text-align: left;
  margin: 1em auto;
}

.img-logo-footer {
  width: 100%;
  text-align: center;
  margin: 1em auto;
}

.card-contain {
  text-align: left;
}

.card-info {
  width: 40%;
  margin: 0 auto;
  padding: 1.5em;
  text-align: center;
  -webkit-box-shadow: 0px 0px 50px -10px rgba(140,138,140,0.5);
  -moz-box-shadow: 0px 0px 50px -10px rgba(140,138,140,0.5);
  box-shadow: 0px 0px 50px -10px rgba(140,138,140,0.5);
  border-radius: 50px;
  cursor: pointer;
}

.border-footer {
  display: flex;
  width: inherit;
  justify-content: center;
}

.border-footer-territoria {
  width: inherit;
  text-align: center;
}

.main-logo {
  display: block;
}

.contain-center {
  text-align: center;
}

.card-lightbox {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  overflow: hidden;
}

.mobile-version {
  display: none
}

.boton-form {
  text-decoration: none;
}

@media only screen and (max-width: 1023px) {
  .modal-body {
    padding: 1em !important;
  }
}

/* @media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
      padding-left: 0 !important;
  }
} */

@media only screen and (max-width: 768px) {
  .mobile-version {
    display: flex;
    background-image: url("./img/imgterritoria.png"); /* The image used */
    width: 100%;
    height: 300px;
    background-position-x: center;
    background-position-y: center;
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
  }
  .contain {
    flex-direction: column;
  }
  .main-logo {
    display: none;
  }
  .card-info {
    width: 85%;
    margin: 1em auto;
  }
  .logo {
    display: none;
  }
  .info {
    margin: 3em 2em;
    width: calc(100% - 5em);
  }
  footer {
    flex-direction: column;
    height: 300px;
    justify-content: center;
  }
  .img-logo {
    margin: 1em;
  }
  .card-lightbox {
    flex-direction: column;
  }
  .border-footer {
    text-align: center;
  }
}